// src/contexts/AuthContext.js - KORRIGIERT

// Entferne useMemo und initGuestSession aus dem Import
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// Importiere nur refreshToken (initGuestSession wird hier nicht gebraucht)
import api, { refreshToken, refreshTokenWithRetry } from '../services/api'; // refreshTokenWithRetry hinzugefügt
import { debounce } from 'lodash';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [authCheckDone, setAuthCheckDone] = useState(false);
    const [isAuthReady, setIsAuthReady] = useState(false); // Wird jetzt bereitgestellt

    const navigate = useNavigate();

    // Hilfsfunktion, um fetchUserProfile global verfügbar zu machen (für Interceptor)
    const triggerFetchUserProfile = useCallback(() => {
        // Diese Funktion ruft die unten definierte, gedrosselte Funktion auf
        // Wir verwenden eine separate Funktion, um Zirkelbezüge in den deps zu vermeiden
        // und um sicherzustellen, dass wir immer die *aktuellste* debounced Funktion aufrufen.
        // Da fetchUserProfile selbst useCallback verwendet (siehe unten),
        // ist diese Funktion hier stabil.
         fetchUserProfileInternal();
    }, []); // Keine Abhängigkeiten hier, da es nur die innere Funktion aufruft

    useEffect(() => {
        // Mache die trigger-Funktion global verfügbar
        window.fetchUserProfileAgain = triggerFetchUserProfile;
        return () => {
            delete window.fetchUserProfileAgain;
        }
    }, [triggerFetchUserProfile]); // Hängt von der stabilen trigger-Funktion ab


    // Die eigentliche, gedrosselte fetchUserProfile Logik
    // Verwende useCallback hier, um sicherzustellen, dass die debounced Funktion
    // nicht bei jedem Render neu erstellt wird, es sei denn, Abhängigkeiten ändern sich.
    const fetchUserProfileInternal = useCallback(
        debounce(async () => {
            console.log("AuthContext: fetchUserProfileInternal() (debounced) called.");
            // Verhindere Ausführung, wenn bereits ein Refresh läuft
            if (isRefreshing) {
                console.log("AuthContext: fetchUserProfileInternal skipped due to ongoing refresh.");
                return;
            }

            console.log("AuthContext: Fetching user profile (debounced)...");
            // Setze Loading erst direkt vor dem API-Call
            setIsAuthLoading(true);
            let didStartProactiveRefresh = false; // Flag, um zu wissen, ob wir unten returnen müssen

            try {
                const response = await api.get('/profile');
                const profileData = response.data;
                console.log("AuthContext: Profile response received", profileData);

                if (profileData.isGuest) {
                    console.log("AuthContext: User is Guest.");
                    const shouldRemember = localStorage.getItem('rememberUser') === 'true';

                    // --- NEUE LOGIK: Proaktiver Refresh bei Bedarf ---
                    if (shouldRemember) {
                        console.log("AuthContext: Guest response received, but 'rememberUser' is true. Attempting proactive refresh.");
                        setIsRefreshing(true); // Setze Refresh-Status
                        didStartProactiveRefresh = true; // Setze Flag

                        const refreshed = await refreshTokenWithRetry(); // Nutze die Funktion aus api.js mit Retries

                        setIsRefreshing(false); // Refresh-Versuch beendet

                        if (refreshed) {
                            console.log("AuthContext: Proactive refresh successful after guest response. Fetching profile again.");
                            // Wichtig: Löse den Fetch erneut aus. setIsAuthLoading wird dort wieder gesetzt.
                            fetchUserProfileInternal.cancel(); // Debounce-Timer abbrechen, falls vorhanden
                            fetchUserProfileInternal(); // Sofort neu auslösen
                            // Beende diese Funktion hier, der neue Fetch übernimmt
                            return;
                        } else {
                            // Refresh ist endgültig fehlgeschlagen
                            console.warn("AuthContext: Proactive refresh failed after guest response. Staying logged out and clearing remember flag.");
                            try { localStorage.removeItem('rememberUser'); } catch(e) { console.error(e); }
                            setUser(null); // Sicherstellen, dass User null ist
                            // isAuthLoading wird im finally gesetzt
                        }
                    } else {
                        // Normaler Gast (kein RememberMe Flag oder Refresh lief schon)
                        console.log("AuthContext: Treating as normal guest (no rememberMe flag).");
                        setUser(null); // Setze User auf null
                        // isAuthLoading wird im finally gesetzt
                    }
                    // --- ENDE NEUE LOGIK ---

                } else {
                    // User ist eingeloggt (isGuest === false)
                    console.log("AuthContext: User is Logged In.", profileData.username);
                    setUser(profileData); // Setze User-Daten
                     // Optional: Prüfen ob rememberUser gesetzt sein sollte, falls nicht (unwahrscheinlich nötig)
                    // isAuthLoading wird im finally gesetzt
                }
            } catch (error) {
                 // Fehlerbehandlung für den api.get('/profile') Aufruf
                 // Der Interceptor sollte 401er für *abgelaufene Tokens* fangen und refreshen.
                 // Wenn hier ein Fehler ankommt, ist es entweder ein anderer Fehler (500, 404 etc.)
                 // ODER der Refresh durch den Interceptor ist fehlgeschlagen.
                 console.error("fetchUserProfileInternal => /profile ERROR:", error?.response?.status, error?.response?.data?.message || error.message);
                 // Bei jedem Fehler hier sollten wir den User als ausgeloggt betrachten
                 // und den RememberMe-Status entfernen, falls der Fehler nach dem initialen Check auftritt.
                 setUser(null); // User-Status zurücksetzen
                 if (authCheckDone) { // Nur löschen, wenn es nicht der allererste Versuch war
                     try { localStorage.removeItem('rememberUser'); } catch(e) { console.error(e); }
                 }
                 // isAuthLoading wird im finally gesetzt
            } finally {
                 // WICHTIG: Das finally wird IMMER ausgeführt, außer wenn wir oben explizit mit 'return' raus sind.
                 // Wenn wir einen proaktiven Refresh gestartet UND erfolgreich den neuen Fetch ausgelöst haben,
                 // wollen wir das Loading hier vielleicht noch nicht beenden?
                 // Aber der neue Fetch setzt es ja sofort wieder. Daher sollte es okay sein.
                 console.log("AuthContext: fetchUserProfileInternal finally block executing.");
                 setIsAuthLoading(false);
                 setAuthCheckDone(true);
                 setIsAuthReady(true); // Provider ist bereit (entweder mit User oder als Gast)
                 console.log("AuthContext: fetchUserProfileInternal finished. isAuthReady set to true.");
            }
        }, 300),
        // Abhängigkeiten aktualisiert: brauchen 'isRefreshing' und 'authCheckDone' für die Logik
        [isRefreshing, authCheckDone, setIsAuthLoading, setUser, setIsAuthReady, setIsRefreshing] // setIsRefreshing hinzugefügt
    );


    // -----------------------------------------------------------
    // 2) Beim App-Start /profile abrufen
    // -----------------------------------------------------------
    useEffect(() => {
        // Nur beim ersten Mount ODER wenn user explizit null ist UND noch geladen wird
        if (isAuthLoading && user === null) {
            console.log("AuthContext: Initial fetchUserProfile triggered.");
            fetchUserProfileInternal(); // Rufe die interne, gedrosselte Funktion auf
        }
        // Wenn user auf null gesetzt wird (z.B. nach Logout oder Fehler),
        // muss nicht unbedingt erneut gefetched werden, der Zustand ist ja dann klar.
    }, [isAuthLoading, user, fetchUserProfileInternal]); // Korrekte Abhängigkeiten (Warnung 5 behoben)

// -----------------------------------------------------------
    // 3) login - Angepasst mit localStorage für RememberMe
    // -----------------------------------------------------------
    const login = async (credentials) => {
        // credentials sollte ein Objekt sein, das { identifier, password, rememberMe } enthält
        setIsAuthLoading(true);
        try {
            const resp = await api.post('/login', credentials); // Sende alle Credentials inkl. rememberMe
            if (resp.status === 200) {
                console.log("AuthContext: Login successful, setting state and fetching profile...");

                // --- NEUE LOGIK START ---
                // Speichere den "Remember Me"-Status basierend auf den übergebenen Credentials
                if (credentials.rememberMe) {
                    try {
                         localStorage.setItem('rememberUser', 'true');
                         console.log("AuthContext: 'rememberUser' flag set in localStorage.");
                    } catch (e) {
                         console.error("AuthContext: Failed to set 'rememberUser' in localStorage", e);
                    }
                } else {
                    try {
                         // Wenn "Remember Me" nicht ausgewählt war, entferne den Eintrag sicherheitshalber
                         localStorage.removeItem('rememberUser');
                         console.log("AuthContext: 'rememberUser' flag removed from localStorage.");
                    } catch (e) {
                         console.error("AuthContext: Failed to remove 'rememberUser' from localStorage", e);
                    }
                }
                // --- NEUE LOGIK ENDE ---

                // Jetzt erst das Profil abrufen, nachdem der Status gespeichert wurde
                await fetchUserProfileInternal(); // Rufe die interne Funktion auf
                // isAuthLoading wird im finally von fetchUserProfileInternal auf false gesetzt
                return true; // Signalisiert Erfolg an die aufrufende Komponente

            } else {
                // Sollte eigentlich nicht passieren, da Axios Fehler bei non-2xx wirft, aber sicher ist sicher
                console.error('AuthContext: Login API call returned non-200 status:', resp.status, resp.statusText);
                setIsAuthLoading(false); // Loading hier beenden
                 // Sicherstellen, dass rememberUser nicht gesetzt ist bei fehlgeschlagenem Login
                try { localStorage.removeItem('rememberUser'); } catch (e) {}
                return false;
            }
        } catch (err) {
            // Fehler vom api.post('/login') wird hier gefangen
            console.error('AuthContext: Fehler beim Login-Aufruf:', err.response?.status, err.response?.data || err.message);
            setIsAuthLoading(false); // Loading hier beenden
            // Sicherstellen, dass rememberUser nicht gesetzt ist bei fehlgeschlagenem Login
             try { localStorage.removeItem('rememberUser'); } catch (e) {}
            throw err; // Fehler weiterwerfen, damit die Login-Komponente ihn anzeigen kann
        }
    };

    // -----------------------------------------------------------
    // 4) logout - bleibt gleich
    // -----------------------------------------------------------
    const logout = useCallback(async (options = { navigate: true }) => {
        console.log(`AuthContext: Logout called. Navigate: ${options.navigate}`);
        try {
            await api.post('/logout');
            console.log("AuthContext: Backend logout notified.");
        } catch (error) {
            console.warn("AuthContext: Backend Logout-Fehler (ignoriert für Client-Logout):", error);
        }
        setUser(null);
            // *** NEU: RememberMe Status entfernen ***
    localStorage.removeItem('rememberUser');
    console.log("AuthContext: 'rememberUser' flag removed from localStorage during logout.");
    // *** ENDE NEU ***
        setIsAuthLoading(false);
        setIsAuthReady(true); // Nach Logout ist der Auth-Status auch "bereit" (nämlich: nicht eingeloggt)
        setAuthCheckDone(true); // Status ist bekannt
        window.isLoggedIn = false;

        if (options.navigate) {
            console.log("AuthContext: Navigating to /login after logout.");
            navigate('/login');
        } else {
            console.log("AuthContext: Logout state changed, no navigation triggered by logout function.");
        }
    }, [navigate]);

    // -----------------------------------------------------------
    // 5) Globale Logout-Funktionen bereitstellen - bleibt gleich
    // -----------------------------------------------------------
     useEffect(() => {
         window.logout = logout;
         window.localLogout = (options = { navigate: false }) => { /* ... bleibt gleich ... */ };
         return () => { /* ... bleibt gleich ... */ };
     }, [logout, navigate]);


    // -----------------------------------------------------------
    // 6) handleOAuthLogin - ruft jetzt interne Funktion auf
    // -----------------------------------------------------------
    const handleOAuthLogin = useCallback(async () => {
        console.log("AuthContext: Handling OAuth Login, fetching profile...");
        await fetchUserProfileInternal(); // Rufe die interne Funktion auf
        return true;
    }, [fetchUserProfileInternal]); // Abhängigkeit von der internen Funktion

    // -----------------------------------------------------------
    // 7) isLoggedIn - bleibt gleich
    // -----------------------------------------------------------
    const isLoggedIn = !!user;

    // Setze window.isLoggedIn - bleibt gleich
    useEffect(() => {
        if (!isAuthLoading && authCheckDone) {
            const newIsLoggedIn = !!user;
            if (window.isLoggedIn !== newIsLoggedIn) {
                console.log(`AuthContext: Auth status changed. User is ${newIsLoggedIn ? 'LOGGED IN' : 'NOT LOGGED IN'}. Setting window.isLoggedIn.`);
                window.isLoggedIn = newIsLoggedIn;
            }
        }
    }, [isAuthLoading, authCheckDone, user]);


    // -----------------------------------------------------------
    // 8) Token-Refresh Monitor (vom Interceptor) - verwendet jetzt triggerFetchUserProfile
    // -----------------------------------------------------------
    useEffect(() => {
        const handleRefreshStarted = () => {
            console.log("AuthContext: Event 'tokenRefreshStarted' received.");
            setIsRefreshing(true);
        };
        const handleRefreshEnded = () => {
            console.log("AuthContext: Event 'tokenRefreshEnded' received.");
            setIsRefreshing(false);
            // Profil neu laden nach Refresh-Versuch (egal ob erfolgreich oder nicht)
            // Verwende die globale trigger Funktion, die wir oben definiert haben
            console.log("AuthContext: Triggering profile fetch again after refresh attempt ended.");
            triggerFetchUserProfile(); // Ruft die stabile Trigger-Funktion auf
        };

        window.addEventListener('tokenRefreshStarted', handleRefreshStarted);
        window.addEventListener('tokenRefreshEnded', handleRefreshEnded);

        return () => {
            window.removeEventListener('tokenRefreshStarted', handleRefreshStarted);
            window.removeEventListener('tokenRefreshEnded', handleRefreshEnded);
        };
    }, [triggerFetchUserProfile]); // Abhängigkeit von der stabilen Trigger-Funktion

    // -----------------------------------------------------------
    // 9) Proaktiver Refresh - bleibt gleich
    // -----------------------------------------------------------
    useEffect(() => {
        let refreshInterval;
        if (isLoggedIn) {
            console.log("AuthContext: User is logged in, starting proactive refresh interval (6 min).");
            refreshInterval = setInterval(() => {
                console.log("AuthContext: Proactive refresh triggered.");
                refreshToken().then((refreshed) => {
                    if (refreshed) {
                        console.log("AuthContext: Proactive refresh successful.");
                    } else {
                        console.warn("AuthContext: Proactive refresh failed. Interceptor will handle on next API call.");
                    }
                }).catch(err => {
                    console.error("AuthContext: Error during proactive refresh:", err);
                });
            }, 360000); // 6 Minuten
        } else {
            console.log("AuthContext: User not logged in, clearing proactive refresh interval.");
            if (refreshInterval) clearInterval(refreshInterval);
        }
        return () => {
            if (refreshInterval) {
                console.log("AuthContext: Clearing proactive refresh interval on cleanup.");
                clearInterval(refreshInterval);
            }
        };
    }, [isLoggedIn]);

    // Stelle sicher, dass der Provider den korrekten Value übergibt
    // Füge isAuthReady hinzu (Warnung 3 behoben)
    return (
        <AuthContext.Provider value={{
            user,
            isLoggedIn,
            isAuthLoading,
            isRefreshing,
            isAuthReady, // <-- HINZUGEFÜGT
            login,
            logout,
            handleOAuthLogin,
        }}>
            {children}
        </AuthContext.Provider>
    );
};