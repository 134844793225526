// src/services/api.js
// Bereinigte und kommentierte Version

import axios from 'axios';
import { toast } from 'react-toastify';
import { t } from '@lingui/macro';

// --- Konstanten ---
const ACCESS_TOKEN_COOKIE = 'accessToken';
const REFRESH_TOKEN_COOKIE = 'refreshToken';
const GUEST_ID_COOKIE = 'guestId';
const LOGOUT_REASON_KEY = 'logoutReason'; // Key für sessionStorage

// Backend Message Keys für kritische Fehler -> sofortiger Logout & Reload
const CRITICAL_ERROR_KEYS = [
    'error.tokenVersionMismatch',
    'error.userBanned',
    'error.accountLocked',
    'error.invalidOrExpiredRefreshToken', // Wenn /refresh selbst fehlschlägt
];

// API Endpunkte
const API_REFRESH_URL = '/api/refresh';
const API_INIT_GUEST_URL = '/api/init-guest';
const API_LOGOUT_URL = '/api/logout'; // Nur relevant, um Fehler von diesem Endpunkt im Interceptor zu ignorieren

// --- Globale Zustandsvariablen für den Interceptor ---
let isRefreshingToken = false; // Verhindert parallele Refresh-Aufrufe
let hasForcedLogoutOccurred = false; // Verhindert mehrfache Logout-Reloads pro Seitenaufruf

// Setzt den Logout-Flag zurück, wenn die Seite komplett neu geladen wird
window.addEventListener('load', () => {
    hasForcedLogoutOccurred = false;
});

// --- Helper Functions ---

/**
 * Löscht die relevanten Authentifizierungs- und Gast-Cookies.
 * Hinweis: Die Optionen (path, domain, secure, sameSite) sollten idealerweise
 * denen entsprechen, die beim Setzen der Cookies verwendet wurden,
 * obwohl 'expires' / 'maxAge' oft ausreicht.
 */
function clearAuthCookies() {
    const expiry = 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    const path = 'path=/;';
    // Ggf. 'SameSite=Lax;' oder 'Strict;' und 'Secure;' hinzufügen, passend zur Setz-Logik
    const options = `${expiry} ${path}`; // Hier ggf. erweitern
    document.cookie = `${ACCESS_TOKEN_COOKIE}=; ${options}`;
    document.cookie = `${REFRESH_TOKEN_COOKIE}=; ${options}`;
    document.cookie = `${GUEST_ID_COOKIE}=; ${options}`;
}

/**
 * Führt einen erzwungenen Logout durch: Speichert Grund, löscht Cookies,
 * versucht AuthContext zu benachrichtigen und erzwingt einen Seiten-Reload.
 * @param {string} [messageKey] - Optionaler Key für die Logout-Nachricht.
 */
function performLogout(messageKey) {
    // Verhindere Endlosschleife auf der Login-Seite
    if (window.location.pathname === '/login') {
        clearAuthCookies(); // Cookies trotzdem löschen
        if (window.logout) { try { window.logout({ navigate: false }); } catch (e) { /* ignore */ } }
        return;
    }

    // Verhindere mehrfache Ausführung vor dem Reload
    if (hasForcedLogoutOccurred) {
        return;
    }
    hasForcedLogoutOccurred = true;

    console.warn(`[API Interceptor] Initiating forced logout sequence. Reason: '${messageKey || 'N/A'}'`);

    // 1. Grund für Anzeige nach Reload speichern
    if (messageKey) {
        try {
            sessionStorage.setItem(LOGOUT_REASON_KEY, messageKey);
        } catch (storageError) {
            console.error("[API Interceptor] Failed to set logoutReason in sessionStorage:", storageError);
        }
    }

    // 2. Cookies löschen
    clearAuthCookies();

    // 3. AuthContext benachrichtigen (falls möglich)
    if (window.logout) {
        try {
            window.logout({ navigate: false }); // Keine Navigation, da Reload folgt
        } catch (stateErr) {
            console.error("[API Interceptor] Error calling window.logout in performLogout:", stateErr);
        }
    } else {
        console.error('[API Interceptor] window.logout was not found! Cannot update AuthContext state directly.');
    }

    // 4. Seiten-Reload erzwingen, um einen sauberen Zustand sicherzustellen
    window.location.reload();
}


/**
 * Initialisiert eine Gast-Session (falls noch nicht vorhanden).
 * Umgeht den Interceptor.
 * @returns {Promise<boolean>} True bei Erfolg.
 */
async function initGuestSession() {
    try {
        // Direkter axios-Aufruf ohne die globale Instanz 'api'
        const response = await axios.get(API_INIT_GUEST_URL, { withCredentials: true });
        return !!response.data?.guestId;
    } catch (err) {
        console.error('[API Interceptor] Error during initGuestSession API call:', err.response?.status, err.response?.data || err.message);
        return false;
    }
}

/**
 * Fordert ein neues Access-Token via /api/refresh an.
 * Umgeht den Interceptor.
 * @returns {Promise<boolean>} True bei Erfolg (Status 200).
 */
async function refreshToken() {
    try {
        // Direkter axios-Aufruf ohne die globale Instanz 'api'
        const response = await axios.post(API_REFRESH_URL, {}, { withCredentials: true });
        if (response.status === 200) {
            return true; // Erfolg signalisiert durch Status 200
        } else {
            console.error('[API Interceptor] Unexpected successful refresh response:', response.status, response.data);
            return false; // Unerwarteter Statuscode
        }
    } catch (err) {
        // Loggt den Fehler, gibt aber nur false zurück. Der Aufrufer entscheidet über Logout.
        console.error('[API Interceptor] Error during refreshToken API call:', err.response?.status, err.response?.data?.messageKey || err.message);
        return false;
    }
}

/**
 * Versucht refreshToken() mit Wiederholungen bei Fehlern.
 * @param {number} [maxRetries=3] Maximale Versuche.
 * @returns {Promise<boolean>} True bei Erfolg, false nach allen Versuchen.
 */
async function refreshTokenWithRetry(maxRetries = 3) {
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
        const refreshed = await refreshToken();
        if (refreshed) {
            return true; // Erfolg
        }
        if (attempt < maxRetries) {
            const delay = 500 * attempt; // Exponentielles Backoff
            await new Promise(resolve => setTimeout(resolve, delay));
        }
    }
    console.warn(`[API Interceptor] Token refresh failed definitively after ${maxRetries} attempts.`);
    return false; // Endgültig fehlgeschlagen
}

// --- Globale Axios-Instanz ---
const api = axios.create({
    baseURL: '/api',
    withCredentials: true, // Wichtig für Cookies
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    timeout: 15000, // Timeout von 15 Sekunden hinzufügen
});

// --- Axios Request Interceptor ---
// (Optional: Nur für Debugging oder spezielle Header)
/*
api.interceptors.request.use(
    (config) => {
        // console.debug(`[API Interceptor] >> Request: ${config.method.toUpperCase()} ${config.url}`);
        return config;
    },
    (error) => {
        console.error("[API Interceptor] !! Request Setup Error:", error);
        return Promise.reject(error);
    }
);
*/

// --- Axios Response Interceptor ---
// Hauptlogik für Fehlerbehandlung und Token-Refresh
api.interceptors.response.use(
    // 1. Erfolgreiche Antworten (Status 2xx) durchlassen
    (response) => response,

    // 2. Fehlerbehandlung
    async (error) => {
        const { config: originalRequest, response } = error;

        // Fehler ohne 'response' Objekt (Netzwerkfehler, Timeout etc.)
        if (!response) {
            console.error("[API Interceptor] Network or other error (no response):", error);
            // Zeige ggf. einen allgemeinen Netzwerkfehler-Toast an
            // toast.error(t({ id: 'api.error.network', message: 'Netzwerkfehler oder Server nicht erreichbar.' }));
            return Promise.reject(error);
        }

        // Status und Daten aus der Antwort extrahieren
        const { status, data } = response;
        const messageKey = data?.messageKey; // Optionaler Key für Frontend-Nachrichten

        // 2a. Fehler von /api/refresh oder /api/logout ignorieren, um Schleifen zu verhindern
        if (originalRequest?.url === API_REFRESH_URL || originalRequest?.url === API_LOGOUT_URL) {
            return Promise.reject(error); // Fehler einfach weitergeben
        }

        // 2b. Rate Limit / Captcha (Status 429)
        if (status === 429) {
            const captchaRequired = response.headers?.['x-captcha-required'] === 'true';
            window.dispatchEvent(new CustomEvent(captchaRequired ? 'captchaRequired' : 'rateLimitExceeded', { detail: true }));
            if (!captchaRequired) {
                 toast.warn(t({ id: 'api.error.rateLimit', message: 'Zu viele Anfragen. Bitte warten Sie einen Moment.' }), { position: 'bottom-right' });
            }
            return Promise.reject(error); // Fehler weitergeben
        }

        // 2c. Authentifizierungsfehler (Status 401)
        if (status === 401) {
            // Prüfen auf kritische Fehler, die sofortigen Logout erfordern
            if (CRITICAL_ERROR_KEYS.includes(messageKey)) {
                console.error(`[API Interceptor] Critical auth error (${messageKey}). Forcing logout!`);
                performLogout(messageKey); // Logout & Reload auslösen
                return Promise.reject(error); // Fehler weitergeben (obwohl Seite neu lädt)
            }

            // Wenn nicht kritisch -> Token-Refresh versuchen
            if (isRefreshingToken) {
                 // Wenn schon ein Refresh läuft, kurz warten und dann den Original-Request wiederholen
                 // (einfache Implementierung ohne Warteschlange)
                 await new Promise(resolve => setTimeout(resolve, 1500)); // Warte kurz
                 // Prüfe, ob der Refresh inzwischen erfolgreich war (neuer Token sollte im Cookie sein)
                 // Wiederhole den Original-Request
                 originalRequest._waitedForRefresh = true; // Markiere, dass gewartet wurde (optional)
                 return api.request(originalRequest); // Axios kümmert sich um das Promise Handling
            } else {
                // Starte den Refresh-Prozess
                 isRefreshingToken = true;
                 window.dispatchEvent(new CustomEvent('tokenRefreshStarted')); // Event für UI/Context

                // Führe Refresh mit Retries durch
                return refreshTokenWithRetry(3).then(async (refreshed) => {
                    if (refreshed) {
                         // Refresh erfolgreich, wiederhole Original-Request
                         originalRequest._isRetryRequest = true; // Markiere als Retry (optional)
                          // AuthContext informieren, dass er ggf. Profil neu laden soll
                         if (window.fetchUserProfileAgain) {
                            setTimeout(() => window.fetchUserProfileAgain(), 100); // Leicht verzögert
                         }
                         return api.request(originalRequest); // Axios kümmert sich um das Promise
                    } else {
                         // Refresh endgültig fehlgeschlagen
                         console.error("[API Interceptor] Token refresh failed definitively. Forcing logout.");
                         performLogout('error.refreshFailed'); // Logout & Reload auslösen
                         return Promise.reject(error); // Ursprünglichen 401-Fehler weitergeben
                    }
                }).catch((refreshProcessError) => {
                    // Unerwarteter Fehler während des Refresh-Ablaufs selbst
                    console.error("[API Interceptor] Unexpected error during refresh process:", refreshProcessError);
                    performLogout('error.refreshError'); // Sicherheitshalber Logout & Reload
                    return Promise.reject(refreshProcessError); // Fehler weitergeben
                }).finally(() => {
                    // Sicherstellen, dass das Flag zurückgesetzt wird und Event gesendet wird
                    isRefreshingToken = false;
                    window.dispatchEvent(new CustomEvent('tokenRefreshEnded'));
                });
            }
        }

        // 2d. Berechtigungsfehler (Status 403)
        if (status === 403) {
            // Prüfen auf kritische Fehler (z.B. Banned/Locked), die Logout erfordern
            if (CRITICAL_ERROR_KEYS.includes(messageKey)) {
                 console.error(`[API Interceptor] Critical auth error (${messageKey}) with status 403. Forcing logout!`);
                 performLogout(messageKey);
                 return Promise.reject(error);
            }
            // Standard 403 -> Keine Berechtigung
             toast.error(t({ id: 'api.error.permissionDenied', message: 'Keine Berechtigung für diese Aktion.' }), { position: 'bottom-right' });
            return Promise.reject(error); // Fehler weitergeben
        }

        // 2e. Andere Fehler (z.B. 500, 404)
        if (status >= 500) {
            // Nur bei Serverfehlern allgemeinen Toast anzeigen
            toast.error(t({ id: 'api.error.serverError', message: 'Ein interner Serverfehler ist aufgetreten.' }), { position: 'bottom-right' });
        }
        // Bei anderen 4xx Fehlern (z.B. 404, 400 Validation Error) wird kein Toast gezeigt,
        // die aufrufende Komponente sollte diese spezifisch behandeln.

        return Promise.reject(error); // Fehler an den Aufrufer weitergeben
    }
);

// --- Exports ---
// Exportiere die konfigurierte Axios-Instanz als Standard
export default api;

// Exportiere spezifische Funktionen, falls sie extern benötigt werden
export { initGuestSession, refreshToken, refreshTokenWithRetry, performLogout };